<template>
  <div>
    <b-card class="mb-1" :title="`Recebíveis de ${month}`">
      <e-charts
        ref="line"
        size
        :options="echatData"
        theme="theme-color"
        auto-resize
      />
    </b-card>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row">
          <b-col cols="12" md="2">
            <div class="form-group mb-md-0">
              <label for="">CPF</label>
              <input type="tel" v-model="filters.cpf" class="form-control" />
            </div>
          </b-col>

          <b-col cols="12" md="2">
            <div class="form-group mb-md-0">
              <label for="">ID</label>
              <input type="tel" v-model="filters.id" class="form-control" />
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <label for="">Status</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="4">
            <div class="form-group mb-md-0">
              <label for="">Período</label>
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <b-col cols="12" md="1">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="margin-top: 20px"
            >
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body v-if="!submitedFilter" class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="identity"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(customer_name)="data">
          <b-media vertical-align="center">
            <span class="d-block text-nowrap">
              {{ data.item.customer_name }}
            </span>
            <p class="mb-0" style="margin-top: 4px">
              <strong><i class="bi bi-credit-card-2-front"></i></strong>
              {{ toMaskCpf(data.item.customer_cpf) }}
            </p>
          </b-media>
        </template>

        <template #cell(plots)="data">
          {{ data.item.plots }}/{{ data.item.total_installments }}
        </template>

        <template #cell(payment_method)="data">
          <i
            style="font-size: 1.3rem"
            :class="`bi ${data.item.payment_method_icon}`"
          ></i>
        </template>

        <template #cell(order_id)="data">
          {{ data.item.order_id }}
        </template>

        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>

        <template #cell(amount)="data">
          {{ data.item.amount | toCurrency }}
        </template>

        <template #cell(total_discount)="data">
          <b-badge
            v-if="data.item.total_discount"
            class="d-block"
            variant="light-success"
          >
            {{ data.item.total_discount | toCurrency }}
          </b-badge>
        </template>

        <template #cell(action)="{ item }">
          <!-- Ícone de Emissão de NF com Tooltip (Apenas se NF não foi gerada) -->
          <span v-if="!item.nfe_id && !item.nfse_id">
            <feather-icon
              :id="`emitir-nf-icon-${item.id}`"
              icon="FileTextIcon"
              size="16"
              class="cursor-pointer mr-1 text-success"
              @click="emitirNotaFiscal(item)"
            />

            <b-tooltip
              triggers="hover"
              :target="`emitir-nf-icon-${item.id}`"
              title="Emitir Nota Fiscal"
              :delay="{ show: 1000, hide: 50 }"
            />
          </span>

          <!-- Ícone de NF-e -->
          <span v-if="item.nfe_id">
            <feather-icon
              :id="`nfe-icon-${item.id}`"
              icon="FileIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="viewNotasFiscais(item)"
            />

            <b-tooltip
              triggers="hover"
              :target="`nfe-icon-${item.id}`"
              title="Visualizar Notas Fiscais"
            />
          </span>

          <!-- Visualizar Pedido -->
          <router-link
            v-if="$can('Pedido - Visualizar', 'Comercial')"
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: item.order_uuid },
            }"
          >
            <feather-icon icon="EyeIcon" size="16" class="cursor-pointer" />
          </router-link>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            pedidos...
          </p>
        </div>
      </div>
    </b-card>

    <NotaFiscalModal
      :showModal="showModal"
      :notaFiscalNFe="notaFiscalNFe"
      :notaFiscalNFSe="notaFiscalNFSe"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "./theme.json";
import moment from "moment";
import "moment/locale/pt-br";

ECharts.registerTheme("theme-color", theme);

import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  VBTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { maskCpf } from "@/_helpers/_urlHelper";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import NotaFiscalModal from "@/components/installments/NotaFiscalModal.vue";
export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    ECharts,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    vSelect,
    BModal,
    NotaFiscalModal,
  },
  data() {
    return {
      month: moment().format("MMMM"),
      filters: {
        rangeDate: `${moment()
          .subtract("20", "week")
          .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`,
        status: "",
        method: "",
        cpf: "",
        product: "",
        id: "",
        categories: "",
        currentPage: 1,
        perPage: 25,
      },
      submitedFilter: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      showModal: false,
      notaFiscalNFSe: {},
      notaFiscalNFe: {},
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "plots",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "order_id",
          label: "Pedido",
          class: "text-center",
        },
        {
          key: "total",
          label: "total",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "date",
          label: "Data",
          class: "text-center",
        },
        {
          key: "paid_at",
          label: "Pago",
          class: "text-center",
        },
        {
          key: "action",
          label: "Ação",
          class: "text-center",
        },
      ],
      perPageOptions: [
        {
          title: "25",
          value: 25,
        },
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      optionsPaymentMethods: [],
      optionsStatus: [],
      optionsProducts: [],
      optionsProductCategories: [],
      echatData: {
        title: {
          text: "Parcelas do mês atual",
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          left: "0",
        },
        grid: {
          top: 60,
          left: 10,
          right: 0,
          bottom: 15,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        series: [],
      },
    };
  },
  methods: {
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    async fetchProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;
      });
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store.dispatch("Product/search", { term: term }).then((res) => {
          this.optionsProducts = res;
        });
      }
    },
    async fetchStatus() {
      this.$store.dispatch("Order/status").then((res) => {
        this.optionsStatus = res;
      });
    },
    async emitirNotaFiscal(item) {
      try {
        this.$swal({
          title: "Emitir Nota Fiscal?",
          text: "Deseja realmente emitir a Nota Fiscal?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, emitir!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("Order/emitirNotaFiscal", {
                installmentId: item.id,
              })
              .then((res) => {
                this.notify(
                  "Notas Fiscais emitida com sucesso!",
                  "UserCheckIcon",
                  "success",
                  ""
                );
              })
              .catch((error) => {
                this.notify(
                  "Erro ao emitir a Nota Fiscal.",
                  "ErrorIcon",
                  "danger",
                  ""
                );
              })
              .finally(() => {
                this.getData();
              });
          }
        });
      } catch (error) {
        console.error(`Erro ao emitir a Nota Fiscal: ${error}`);
      }
    },
    async fetchPaymentMethods() {
      this.$store.dispatch("Order/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    submitFilter() {
      this.getData();
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    async viewNotasFiscais(item) {
      const p1 = this.$store.dispatch("Order/viewNFE", item.nfe_id);
      const p2 = this.$store.dispatch("Order/viewNFSE", item.nfse_id);

      const [nfe, nfse] = await Promise.all([p1, p2]);
      this.notaFiscalNFe = nfe.data;
      this.notaFiscalNFSe = nfse.data;
      this.showModal = true;
    },
    async sendNFProduct(item) {
      try {
        await this.$store.dispatch("Order/sendNFProduct", {
          id: item.nfe_id,
        });

        this.notify(
          "Nota Fiscal enviada com sucesso!",
          "UserCheckIcon",
          "success",
          ""
        );
      } catch (error) {
        this.notify("Erro ao enviar a Nota Fiscal.", "ErrorIcon", "danger", "");
      }
    },
    async sendNFService(item) {
      try {
        await this.$store.dispatch("Order/sendNFService", {
          id: item.nfse_id,
        });

        this.notify(
          "Nota Fiscal enviada com sucesso!",
          "UserCheckIcon",
          "success",
          ""
        );
      } catch (error) {
        this.notify("Erro ao enviar a Nota Fiscal.", "ErrorIcon", "danger", "");
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        status: this.filters.status ? this.filters.status.code : "",
        method: this.filters.method ? this.filters.method.code : "",
        product: this.filters.product ? this.filters.product.code : "",
        coupon: this.filters.coupon ? this.filters.coupon.code : "",
        category: this.filters.category ? this.filters.category.code : "",
        cpf: this.filters.cpf ?? "",
        id: this.filters.id ?? "",
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("Order/allInstallments", filtersSearch)
        .then((items) => {
          if (items.orders) {
            this.dataItems = items.orders.data;
            this.rowsTable = items.orders.data.length;
            this.totalRows = items.orders.meta.total;
            this.filters.currentPage = items.orders.meta.current_page;

            const orderDays = [];

            items.days.forEach((day) => {
              let checkAddOrder = false;
              items.installments.filter((installment) => {
                if (installment.date === day) {
                  checkAddOrder = true;
                  orderDays.push(installment.total);
                }
              });

              if (!checkAddOrder) {
                orderDays.push(0);
              }
            });

            const seriesData = {
              name: `Parcelas`,
              type: "line",
              stack: "Total",
              showSymbol: false,
              barMaxWidth: "20%",
              barMinWidth: "10px",
              data: orderDays,
              symbol: "R$ ",
            };

            this.echatData.xAxis[0].data = Object.values(items.days);
            this.echatData.series = seriesData;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    methodIcon(item) {
      switch (item) {
        case 1:
          return "credit-card";
        case 2:
          return "upc-scan";
        case 3:
          return "qr-code-scan";
        default:
          return "arrow-down-up";
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-installments-table");
  },
  mounted() {
    this.getData();
    this.fetchProductCategories();
    this.fetchStatus();
    this.fetchPaymentMethods();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-installments-table", () => {
      this.$swal({
        title: "Exportar Parcelas",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de parcelas.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/exportInstallments", {
              filters: {
                rangeDate: this.filters.rangeDate ?? "",
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                product: this.filters.product ? this.filters.product.code : "",
                coupon: this.filters.coupon ? this.filters.coupon.code : "",
                category: this.filters.category
                  ? this.filters.category.code
                  : "",
                cpf: this.filters.cpf ?? "",
                id: this.filters.coupon ?? "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.echarts {
  width: 100%;
}
.per-page-selector {
  width: 90px;
}

.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
