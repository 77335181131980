<template>
  <b-modal v-model="showModal" title="Detalhes da Nota Fiscal" size="lg">
    <b-card>
      <b-row>
        <b-col v-if="notaFiscalNFe" md="6">
          <h4 class="text-primary">NF-e (Produto)</h4>
          <p><strong>ID:</strong> {{ notaFiscalNFe.id }}</p>
          <p><strong>Número:</strong> {{ notaFiscalNFe.numero }}</p>
          <p><strong>Série:</strong> {{ notaFiscalNFe.serie }}</p>
          <p>
            <strong>Situação:</strong>
            {{ getSituacaoNFE(notaFiscalNFe.situacao) }}
          </p>
          <p>
            <strong>Data de Emissão:</strong>
            {{ formatarData(notaFiscalNFe.dataEmissao) }}
          </p>
          <p>
            <strong>Valor Total:</strong> R$
            {{
              notaFiscalNFe.valorNota
                ? notaFiscalNFe.valorNota.toFixed(2)
                : "0.00"
            }}
          </p>
          <p>
            <strong>Frete:</strong> R$
            {{
              notaFiscalNFe.valorFrete
                ? notaFiscalNFe.valorFrete.toFixed(2)
                : "0.00"
            }}
          </p>
          <p v-if="notaFiscalNFe.linkPDF">
            <a :href="notaFiscalNFe.linkPDF" target="_blank">Ver DANFE (PDF)</a>
          </p>
        </b-col>

        <!-- NFS-e (Nota Fiscal de Serviço) -->
        <b-col v-if="notaFiscalNFSe" md="6">
          <h4 class="text-info">NFS-e (Serviço)</h4>
          <p><strong>ID:</strong> {{ notaFiscalNFSe.id }}</p>
          <p>
            <strong>Número RPS:</strong>
            {{ notaFiscalNFSe.numeroRPS || "Não informado" }}
          </p>
          <p><strong>Série:</strong> {{ notaFiscalNFSe.serie }}</p>
          <p>
            <strong>Situação:</strong>
            {{ getSituacaoNota(notaFiscalNFSe.situacao) }}
          </p>
          <p>
            <strong>Data de Emissão:</strong>
            {{ formatarData(notaFiscalNFSe.dataEmissao) }}
          </p>
          <p>
            <strong>Valor:</strong> R$
            {{
              notaFiscalNFSe.valor ? notaFiscalNFSe.valor.toFixed(2) : "0.00"
            }}
          </p>
          <p>
            <strong>Código de Verificação:</strong>
            {{ notaFiscalNFSe.codigoVerificacao || "Não disponível" }}
          </p>
          <p v-if="notaFiscalNFSe.link">
            <a :href="notaFiscalNFSe.link" target="_blank">Ver NF-e</a>
          </p>
        </b-col>
      </b-row>

      <h5 class="mt-3">Comprador</h5>
      <b-row v-if="notaFiscalNFe.contato || notaFiscalNFSe.contato">
        <b-col md="6">
          <p>
            <strong>Nome:</strong>
            {{ notaFiscalNFe.contato.nome || notaFiscalNFSe.contato.nome }}
          </p>
          <p>
            <strong>Documento:</strong>
            {{
              notaFiscalNFe.contato.numeroDocumento ||
              notaFiscalNFSe.contato.numeroDocumento
            }}
          </p>
          <p>
            <strong>Telefone:</strong>
            {{
              notaFiscalNFe.contato.telefone || notaFiscalNFSe.contato.telefone
            }}
          </p>
          <p>
            <strong>Email:</strong>
            {{ notaFiscalNFe.contato.email || notaFiscalNFSe.contato.email }}
          </p>
        </b-col>
        <b-col
          md="6"
          v-if="
            notaFiscalNFe.contato.endereco || notaFiscalNFSe.contato.endereco
          "
        >
          <p>
            <strong>Endereço:</strong>
            {{
              notaFiscalNFe.contato.endereco.endereco ||
              notaFiscalNFSe.contato.endereco.endereco
            }},
            {{
              notaFiscalNFe.contato.endereco.numero ||
              notaFiscalNFSe.contato.endereco.numero
            }}
          </p>
          <p>
            <strong>Bairro:</strong>
            {{
              notaFiscalNFe.contato.endereco.bairro ||
              notaFiscalNFSe.contato.endereco.bairro
            }}
          </p>
          <p>
            <strong>Cidade:</strong>
            {{
              notaFiscalNFe.contato.endereco.municipio ||
              notaFiscalNFSe.contato.endereco.municipio
            }}
            -
            {{
              notaFiscalNFe.contato.endereco.uf ||
              notaFiscalNFSe.contato.endereco.uf
            }}
          </p>
          <p>
            <strong>CEP:</strong>
            {{
              notaFiscalNFe.contato.endereco.cep ||
              notaFiscalNFSe.contato.endereco.cep
            }}
          </p>
        </b-col>
      </b-row>

      <div v-if="notaFiscalNFe.itens">
        <h5 class="mt-3" v-if="notaFiscalNFe.itens.length">Itens da NF-e</h5>
        <b-table
          v-if="notaFiscalNFe.itens.length"
          striped
          hover
          :items="notaFiscalNFe.itens"
          :fields="fieldsItens"
        ></b-table>
      </div>
    </b-card>
    <template #modal-footer>
      <b-button variant="danger" @click="$emit('close')">Fechar</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BTooltip,
  BModal,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BTooltip,
    BModal,
  },
  props: {
    showModal: Boolean,
    notaFiscalNFe: Object,
    notaFiscalNFSe: Object,
  },
  data() {
    return {
      fieldsItens: [
        { key: "codigo", label: "Código" },
        { key: "descricao", label: "Descrição" },
        { key: "quantidade", label: "Qtd" },
        { key: "valor", label: "Preço Unitário" },
        { key: "valorTotal", label: "Total" },
      ],
    };
  },
  methods: {
    formatarData(data) {
      return new Date(data).toLocaleDateString("pt-BR");
    },
    getSituacaoNota(situacao) {
      const status = {
        0: "Pendente",
        1: "Emitida",
        2: "Disponível para consulta",
        3: "Cancelada",
      };
      return status[situacao] || "Desconhecido";
    },
    getSituacaoNFE(situacao) {
      const status = {
        1: "Pendente",
        2: "Cancelada",
        3: "Aguardando recibo",
        4: "Rejeitada",
        5: "Autorizada",
        6: "Emitida DANFE",
        7: "Registrada",
        8: "Aguardando protocolo",
        9: "Denegada",
        10: "Consulta situação",
        11: "Bloqueada",
      };
      return status[situacao] || "Desconhecido";
    },
  },
};
</script>
