var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { title: "Detalhes da Nota Fiscal", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Fechar")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _vm.notaFiscalNFe
                ? _c("b-col", { attrs: { md: "6" } }, [
                    _c("h4", { staticClass: "text-primary" }, [
                      _vm._v("NF-e (Produto)"),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("ID:")]),
                      _vm._v(" " + _vm._s(_vm.notaFiscalNFe.id)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Número:")]),
                      _vm._v(" " + _vm._s(_vm.notaFiscalNFe.numero)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Série:")]),
                      _vm._v(" " + _vm._s(_vm.notaFiscalNFe.serie)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Situação:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getSituacaoNFE(_vm.notaFiscalNFe.situacao)
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Data de Emissão:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatarData(_vm.notaFiscalNFe.dataEmissao)
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Valor Total:")]),
                      _vm._v(
                        " R$ " +
                          _vm._s(
                            _vm.notaFiscalNFe.valorNota
                              ? _vm.notaFiscalNFe.valorNota.toFixed(2)
                              : "0.00"
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Frete:")]),
                      _vm._v(
                        " R$ " +
                          _vm._s(
                            _vm.notaFiscalNFe.valorFrete
                              ? _vm.notaFiscalNFe.valorFrete.toFixed(2)
                              : "0.00"
                          ) +
                          " "
                      ),
                    ]),
                    _vm.notaFiscalNFe.linkPDF
                      ? _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.notaFiscalNFe.linkPDF,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Ver DANFE (PDF)")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.notaFiscalNFSe
                ? _c("b-col", { attrs: { md: "6" } }, [
                    _c("h4", { staticClass: "text-info" }, [
                      _vm._v("NFS-e (Serviço)"),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("ID:")]),
                      _vm._v(" " + _vm._s(_vm.notaFiscalNFSe.id)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Número RPS:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFSe.numeroRPS || "Não informado"
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Série:")]),
                      _vm._v(" " + _vm._s(_vm.notaFiscalNFSe.serie)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Situação:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getSituacaoNota(_vm.notaFiscalNFSe.situacao)
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Data de Emissão:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatarData(_vm.notaFiscalNFSe.dataEmissao)
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Valor:")]),
                      _vm._v(
                        " R$ " +
                          _vm._s(
                            _vm.notaFiscalNFSe.valor
                              ? _vm.notaFiscalNFSe.valor.toFixed(2)
                              : "0.00"
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Código de Verificação:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFSe.codigoVerificacao ||
                              "Não disponível"
                          ) +
                          " "
                      ),
                    ]),
                    _vm.notaFiscalNFSe.link
                      ? _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.notaFiscalNFSe.link,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Ver NF-e")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [_vm._v("Comprador")]),
          _vm.notaFiscalNFe.contato || _vm.notaFiscalNFSe.contato
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("p", [
                      _c("strong", [_vm._v("Nome:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFe.contato.nome ||
                              _vm.notaFiscalNFSe.contato.nome
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Documento:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFe.contato.numeroDocumento ||
                              _vm.notaFiscalNFSe.contato.numeroDocumento
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Telefone:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFe.contato.telefone ||
                              _vm.notaFiscalNFSe.contato.telefone
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Email:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notaFiscalNFe.contato.email ||
                              _vm.notaFiscalNFSe.contato.email
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.notaFiscalNFe.contato.endereco ||
                  _vm.notaFiscalNFSe.contato.endereco
                    ? _c("b-col", { attrs: { md: "6" } }, [
                        _c("p", [
                          _c("strong", [_vm._v("Endereço:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.endereco ||
                                  _vm.notaFiscalNFSe.contato.endereco.endereco
                              ) +
                              ", " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.numero ||
                                  _vm.notaFiscalNFSe.contato.endereco.numero
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("Bairro:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.bairro ||
                                  _vm.notaFiscalNFSe.contato.endereco.bairro
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("Cidade:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.municipio ||
                                  _vm.notaFiscalNFSe.contato.endereco.municipio
                              ) +
                              " - " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.uf ||
                                  _vm.notaFiscalNFSe.contato.endereco.uf
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("CEP:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notaFiscalNFe.contato.endereco.cep ||
                                  _vm.notaFiscalNFSe.contato.endereco.cep
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.notaFiscalNFe.itens
            ? _c(
                "div",
                [
                  _vm.notaFiscalNFe.itens.length
                    ? _c("h5", { staticClass: "mt-3" }, [
                        _vm._v("Itens da NF-e"),
                      ])
                    : _vm._e(),
                  _vm.notaFiscalNFe.itens.length
                    ? _c("b-table", {
                        attrs: {
                          striped: "",
                          hover: "",
                          items: _vm.notaFiscalNFe.itens,
                          fields: _vm.fieldsItens,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }